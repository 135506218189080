<script setup lang="ts">
  import Tag from 'primevue/tag';
  import { useRouter } from '#imports';
  import { NuxtLink } from '#components';
  import { useRoute } from '#app';
  import type { MenuItem } from '~/menu-items.ts';

  defineProps<{
    isSidebarOpen?: boolean;
    isRoot: boolean;
    menus: MenuItem[];
  }>();

  const router = useRouter();
  const route = useRoute();

  function isActiveRootMenuItem(menuItem: MenuItem) {
    return (
      menuItem.children &&
      !menuItem.children.some(
        (item) =>
          item.to === `/${router.currentRoute.value.name.replace('-', '/')}` ||
          (item.children && item.children.some((it) => it.to === `/${router.currentRoute.value.name}`)),
      )
    );
  }
</script>

<script lang="ts">
  // eslint-disable-next-line import/order,import/first
  import styleclass from 'primevue/styleclass';
  // to allow intellisense for primevue/styleclass directive
  export default {
    directives: {
      styleclass,
    },
  };
</script>

<template>
  <li v-for="(menuitem, index) in menus" :key="`_root${index}`" class="layout-menu-li">
    <button
      v-if="menuitem.children && isRoot"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'slidedown',
        leaveToClass: 'hidden',
        leaveActiveClass: 'slideup',
      }"
      class="menu-clickable"
      type="button"
    >
      <span class="menu-icon">
        <i :class="menuitem.icon"></i>
      </span>
      <span v-if="isSidebarOpen">{{ menuitem.name }}</span>
      <i class="menu-toggle-icon pi pi-angle-down" :class="isSidebarOpen ? '' : 'menu-toggle-right'"></i>
    </button>
    <a v-if="menuitem.href" class="menu-clickable" :href="menuitem.href" target="_blank" rel="noopener noreferrer">
      <span v-if="menuitem.icon" class="menu-icon">
        <i :class="menuitem.icon"></i>
      </span>
      <span v-if="isSidebarOpen">{{ menuitem.name }}</span>
      <Tag v-if="menuitem.badge" :value="menuitem.badge" class="ml-auto"></Tag>
    </a>
    <NuxtLink v-if="menuitem.to" class="menu-clickable" :to="menuitem.to" :class="{ 'router-link-active': menuitem.to === route.fullPath }">
      <span v-if="menuitem.icon" class="menu-icon">
        <i :class="menuitem.icon"></i>
      </span>
      <span v-if="isSidebarOpen">{{ menuitem.name }}</span>
      <Tag v-if="menuitem.badge" :value="menuitem.badge"></Tag>
    </NuxtLink>

    <span v-if="!isRoot && menuitem.children" class="menu-child-category">{{ menuitem.name }}</span>
    <div
      v-if="menuitem.children"
      class="transition-duration-400 transition-ease-in-out flex overflow-y-hidden pl-7 transition-all"
      :class="{ hidden: menuitem.children && isRoot && isActiveRootMenuItem(menuitem) && !isSidebarOpen }"
    >
      <ol class="w-full">
        <MQSidebarMenus :is-root="false" :menus="menuitem.children" :is-sidebar-open="isSidebarOpen" />
      </ol>
    </div>
  </li>
</template>

<style scoped>
  .layout-menu-li {
    list-style-type: none;
    margin-bottom: 0.2rem;
  }

  .menu-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-clickable {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    gap: 12px 4px;
    background-clip: border-box;
    transition: background-color 0.2s;
  }

  .menu-clickable.router-link-active {
    background-color: #dcdcdc;
  }

  .menu-clickable .menu-clickable {
    padding-left: 1rem;
  }

  .menu-toggle-icon {
    position: absolute;
    right: 1rem;
    bottom: 50%;
    transition: transform 0.2s;
    transform: translateY(50%);
  }
  .menu-toggle-icon.menu-toggle-right {
    bottom: 4px;
    right: 0.2rem;
    transform: rotate(-45deg);
  }

  .menu-toggle-icon .menu-clickable:hover {
    background-color: red;
  }

  .slidedown {
    animation: slidedown 0.45s ease-in-out;
  }

  .slideup {
    animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
  }

  @keyframes slidedown {
    0% {
      max-height: 0;
    }
    100% {
      /* height auto does work somehow */
      max-height: auto;
    }
  }

  @keyframes slideup {
    0% {
      max-height: 1000px;
    }
    100% {
      max-height: 0;
    }
  }
</style>
