import { onMounted, ref } from 'vue';
import useUserAction from '~/_composables/useUserAction.js';
import { useUserStore } from '~/modules/shared/stores/user-store.ts';
import { authSdk } from '~/internals/auth/auth-sdk.ts';
import useNavigate from '~/_composables/useNavigate.ts';

export interface UseEagerConnectOptions {
  onReclaimError?: (err: Error) => void;
}

function useEagerSignIn(options: UseEagerConnectOptions = {}) {
  const { reclaimSession, hasStoredSession } = useUserAction();
  const userStore = useUserStore();
  const { navigate } = useNavigate();
  const isChecking = ref(true);

  onMounted(async () => {
    if (!(await hasStoredSession())) {
      await navigate('/sign-in');
      isChecking.value = false;
      return;
    }
    try {
      await reclaimSession();
    } catch (e) {
      options.onReclaimError?.(e as Error);
      userStore.fetchProfileError = e as Error;
      await authSdk.signOut();
    } finally {
      userStore.isEagerring = false;
      isChecking.value = false;
    }
  });

  return {
    isChecking,
  };
}
export default useEagerSignIn;
