import { defineStore } from 'pinia';

export interface AppState {
  isSidebarOpen: boolean;
  sidebarExpandedKeys: string[];
}

export const useAppStore = defineStore('app', {
  state(): AppState {
    return {
      isSidebarOpen: true,
      sidebarExpandedKeys: [],
    };
  },
  actions: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
});
