<script setup lang="ts">
  import { ref } from 'vue';
  import { useUserStore } from '~/modules/shared/stores/user-store.ts';

  const isEnterAdmin = ref(false);

  const { user } = useUserStore();

  if (user) {
    isEnterAdmin.value = true;
  } else {
    isEnterAdmin.value = false;
  }
</script>

<template>
  <div v-if="isEnterAdmin">
    <slot />
  </div>
  <div v-else class="relative flex min-h-screen items-center justify-center">
    <p class="text-xl">Bạn không có quyền truy cập trang này</p>
  </div>
</template>

<style scoped></style>
