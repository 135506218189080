<script lang="ts" setup>
  // import useFetchPreData from '~/_composables/useFetchPreData.ts';
  import AdminFallback from '~/modules/shared/components/AdminFallback.vue';
  import MQDrawer from '~/modules/shared/components/MQDrawer.vue';
  import { useAppStore } from '~/modules/shared/stores/app-store.ts';
  import useEagerSignIn from '~/_composables/useEagerSignIn.ts';
  import { NuxtLoadingIndicator } from '#components';

  const { isChecking } = useEagerSignIn({
    onReclaimError(err: any) {
      console.log(err);
    },
  });

  const appStore = useAppStore();
  // useFetchPreData();
</script>

<template>
  <div class="relative min-h-screen">
    <div v-if="isChecking">
      <NuxtLoadingIndicator />
    </div>
    <div v-else>
      <AdminFallback>
        <MQDrawer />
        <div class="p-4 transition-all duration-200" :class="appStore.isSidebarOpen ? 'ml-[300px]' : 'ml-[80px]'">
          <slot />
        </div>
      </AdminFallback>
    </div>
  </div>
</template>

<style scoped></style>
