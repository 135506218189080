export interface MenuItem {
  name: string;
  icon?: string;
  to?: string; // to use for internal link
  href?: string; // href use for external link
  badge?: string;
  children?: MenuItem[];
}

export const MENUS: MenuItem[] = [
  {
    name: 'Quản trị danh mục',
    icon: 'pi pi-file-edit',
    children: [
      {
        name: 'Bài đăng',
        to: '/posts',
        icon: 'pi pi-file',
      },
      {
        name: 'Tin hot',
        to: '/posts-hot',
        icon: 'pi pi-file',
      },
      {
        name: 'Xác thực tin đăng',
        to: '/posts-verify',
        icon: 'pi pi-file',
      },
      {
        name: 'Xác thực tin chính chủ',
        to: '/posts-owner',
        icon: 'pi pi-file',
      },
    ],
  },
  {
    name: 'Thanh toán',
    icon: 'pi pi-user',
    to: '/payment',
  },
  {
    name: 'Tài khoản',
    icon: 'pi pi-user',
    to: '/users',
  },
  {
    name: 'Cài đặt',
    icon: 'pi pi-cog',
    children: [
      {
        name: 'Banner',
        to: '/banners',
        icon: 'pi pi-image',
      },
    ],
  },
];
