<script setup lang="ts">
  import Menu from 'primevue/menu';
  import { ref } from '#imports';
  import UserDefaultIcon from '~/_components/svg-icons/UserDefaultIcon.vue';
  import useUserAction from '~/_composables/useUserAction.ts';
  import { useAppStore } from '~/modules/shared/stores/app-store.ts';
  import MqhouseLogo from '~/_components/svg-icons/MqhouseLogo.vue';
  import { MENUS } from '~/menu-items.ts';
  import MQSidebarMenus from '~/modules/shared/components/MQSidebarMenus.vue';
  import { useUserStore } from '~/modules/shared/stores/user-store.ts';
  import { formatUserName } from '~/common/utils.ts';

  const appStore = useAppStore();
  const userStore = useUserStore();
  const { signOut } = useUserAction();

  const menu = ref();
  const items = ref([
    {
      label: 'Đăng xuất',
      icon: 'pi pi-sign-out',
      command: async () => {
        try {
          await signOut({ redirectTo: '/sign-in' });
        } catch (e) {}
      },
    },
  ]);

  const toggleMenu = (event: any) => {
    menu.value.toggle(event);
  };
</script>

<template>
  <div
    class="card fixed inset-y-0 left-0 flex justify-center border-[red] bg-white shadow-xl transition-all duration-200"
    :class="appStore.isSidebarOpen ? 'min-w-[280px] max-w-[280px]' : 'min-w-[70px]'"
  >
    <div class="absolute right-[-13px] mt-4">
      <button
        class="flex size-6 items-center justify-center rounded-full bg-white shadow-md"
        size="small"
        text
        :rounded="true"
        aria-label="Close"
        @click="appStore.isSidebarOpen = !appStore.isSidebarOpen"
      >
        <i class="pi pi-chevron-left text-[12px] transition-all duration-200" :class="appStore.isSidebarOpen ? '' : 'rotate-180'" />
      </button>
    </div>
    <div class="flex size-full flex-col">
      <div class="flex shrink-0 items-center gap-x-3 px-1 pt-4">
        <MqhouseLogo class="h-[30px]" />
        <div class="text-2xl font-bold transition-all duration-200" :class="appStore.isSidebarOpen ? '' : 'w-[0] overflow-hidden'">
          MQHouse
        </div>
      </div>

      <div class="mt-4">
        <MQSidebarMenus :is-sidebar-open="appStore.isSidebarOpen" :is-root="true" :menus="MENUS" />
      </div>
      <div class="mt-auto">
        <hr class="border-t-1 mx-3 mb-3 border-none border-surface-200 dark:border-surface-700" />
        <button class="m-4 flex items-center gap-x-3" @click="toggleMenu">
          <img v-if="userStore.user?.avatar!" class="size-8 shrink-0 rounded-full" alt="avatar" :src="userStore.user?.avatar!" />
          <UserDefaultIcon v-else class="size-[24px] text-primary" />
          <span :class="appStore.isSidebarOpen ? '' : 'hidden'" class="overflow-ellipsis font-bold">
            {{ userStore.user?.email ? formatUserName(userStore.user?.email) : '' }}
          </span>
        </button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
